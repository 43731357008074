export const getQueryValue = (
  value?: string | string[],
  options?: {
    decode?: boolean;
  },
): string | undefined => {
  const firstValue = value && (typeof value === 'string' ? value : value[0]);
  if (firstValue) {
    return options?.decode ? decodeURIComponent(firstValue) : firstValue;
  }
};
