import {type AxiosResponse} from 'axios';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {type FetchHasSessionResponse} from 'dp-account/src/features/id-token/repositories/account/fetch-has-session';
import {type UseAxiosMutationOptions} from '../../../types/react-query';
import {sessionCreate} from '../repositories/session-create';
import {createUseHasSessionKey} from './use-fetch-has-session';

export const useSessionCreate = (options?: UseAxiosMutationOptions<void>) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      return sessionCreate();
    },
    {
      ...options,
      onSuccess(...args) {
        void queryClient.refetchQueries(createUseHasSessionKey());
        void options?.onSuccess?.(...args);
      },
    },
  );
};
