import {type FirebaseError} from 'firebase/app';
import {
  type AdditionalUserInfo,
  type AuthError,
  getAdditionalUserInfo,
} from 'firebase/auth';
import {firebaseAuth} from '../../firebase-core/src/auth/auth';
import {useGetRedirectResult} from '../../firebase-core/src/hooks/use-get-redirect-result';
import {useSessionCreate} from './use-session-create';

type HandleRedirectionOptions = {
  onSignIn?: () => void;
  onUserCreate?: (userInfo: AdditionalUserInfo) => void;
  onAnonymousUserCreate?: (userInfo: AdditionalUserInfo) => void;
  onError?: (error: AuthError | FirebaseError | Error) => void;
};

type HandleRedirectionResult = {
  isLoading: boolean;
  error?: AuthError | FirebaseError | Error;
};

export const useHandleRedirection = (
  options?: HandleRedirectionOptions,
): HandleRedirectionResult => {
  const {
    mutate: sessionCreate,
    isLoading: isSessionCreateLoading,
    error: sessionCreateError,
  } = useSessionCreate({
    onSuccess: options?.onSignIn,
    onError: options?.onError,
  });

  const {isLoading: isGetRedirectResultLoading, error: getRedirectResultError} =
    useGetRedirectResult({
      onSuccess(userCredentialOfRedirectResult) {
        if (!userCredentialOfRedirectResult) {
          return;
        }

        const userInfo = getAdditionalUserInfo(userCredentialOfRedirectResult);
        if (userInfo?.isNewUser) {
          if (firebaseAuth.currentUser?.isAnonymous) {
            options?.onAnonymousUserCreate?.(userInfo);
          } else {
            options?.onUserCreate?.(userInfo);
          }
        } else {
          sessionCreate();
        }
      },
      onError: options?.onError,
    });

  const error = getRedirectResultError ?? sessionCreateError ?? undefined;
  const isLoading = isGetRedirectResultLoading || isSessionCreateLoading;

  return {
    isLoading,
    error,
  };
};
